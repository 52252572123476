import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Phase from "./Phase";
import { Redirect } from "react-router-dom";
import "../layouts/css/Game.css";
import scoreLogo from "../../assets/bottomBar/score.svg";
import playerNumLogo from "../../assets/bottomBar/playerNum.svg";
import groupLogo from "../../assets/bottomBar/group.svg";
import { saveState } from "../../localStorage.js";

const Game = ({
  score,
  name,
  group,
  groupOne,
  groupTwo,
  gameKey,
  login,
  isAuthenticated,
}) => {
  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  saveState({ number: name, gameKey: gameKey });
  let BottomBar = (
    <>
      <div className="bottomBar">
        <div className="icon-desc-val">
          <img alt="Player Number" className="game-icon" src={playerNumLogo} />
          <h1 className="game-text">מספרך:</h1>
          <h1
            className="game-text"
            style={{ fontSize: "4vmin", marginLeft: "0.5vw" }}
          >
            {name}
          </h1>
        </div>

        <div className="icon-desc-val">
          <img alt="Your Score" className="game-icon" src={scoreLogo} />
          <h1 className="game-text">ניקודך:</h1>
          <h1
            className="game-text"
            style={{ fontSize: "4vmin", marginLeft: "0.5vw" }}
          >
            {score}
          </h1>
        </div>

        <div className="icon-desc-val">
          <img alt="Group" className="game-icon" src={groupLogo} />
          <h1 className="game-text">קבוצתך:</h1>
          <h1
            className="game-text"
            style={{ fontSize: "4vmin", marginLeft: "0.5vw" }}
          >
            {group}
          </h1>
        </div>

        <div className="icon-desc-val">
          <h1 className="game-text">ניקוד #1:</h1>
          <h1
            className="game-text"
            style={{ fontSize: "4vmin", marginLeft: "0.5vw" }}
          >
            {groupOne}
          </h1>
        </div>

        <div className="icon-desc-val">
          <h1 className="game-text">ניקוד #2:</h1>
          <h1
            className="game-text"
            style={{ fontSize: "4vmin", marginLeft: "0.5vw" }}
          >
            {groupTwo}
          </h1>
        </div>
      </div>
    </>
  );
  return (
    <div>
      <div className="game-fullscreen">
        <Phase />
      </div>
      {BottomBar}
    </div>
  );
};
Game.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired,
  groupOne: PropTypes.number,
  groupTwo: PropTypes.number,
  name: PropTypes.number.isRequired,
  group: PropTypes.string.isRequired,
  gameKey: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  score: state.user.userState.score,
  groupOne: state.user.userState.groupOne,
  groupTwo: state.user.userState.groupTwo,
  name: state.user.name,
  group: state.user.group,
  gameKey: state.user.gameKey,
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {})(Game);
