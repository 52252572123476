import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import correctSvg from "../../assets/success-green-check-mark.svg";
import incorrectSvg from "../../assets/wrong.svg";
import "../layouts/css/Bars.css";
import "../layouts/css/Questions.css";
const Bars = ({
  distribution,
  correctAnswer,
  correctTerm,
  userAnswer,
  audioKey,
  knowledge,
  question,
  answers,
}) => {
  const windowSize = useWindowSize();
  let correctAns = (
    <title style={{ display: "inline-block" }}>
      Correct answer is{" "}
      <title style={{ display: "inline-block", color: "green" }}>
        {correctTerm}
      </title>
    </title>
  );
  let distributionPercent = castToPercent(distribution);
  let imagesByResult = imagesSetter(
    Object.keys(distribution).length,
    correctAnswer,
    userAnswer,
    knowledge
  );
  let colorSetOld = [
    "#0ead69",
    "#FED636",
    "#61DCEB",
    "#FE4E02",
    "#d4d5fd",
    "#f86624",
  ];
  let colorSet = [
    "#6BE2B2",
    "#FED636",
    "#61DCEB",
    "#FE4E02",
    "#d4d5fd",
    "#f86624",
  ];
  const data = {
    labels: Object.keys(distribution),
    datasets: [
      {
        maxBarThickness: windowSize.width * 0.15,
        backgroundColor: colorSet,
        borderColor: colorSet,
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.values(distributionPercent),
        datalabels: {
          anchor: "center",
          offset: 0,
          backgroundColor: function (ctx) {
            return null;
          },
          borderColor: function (ctx) {
            return null;
          },
          font: {
            weight: "bold",
            size: Math.min(windowSize.height, windowSize.width) * 0.05,
          },
          color: function (ctx) {
            var value = ctx.dataset.data[ctx.dataIndex];
            return value > 0 ? "#273043" : null;
            // return "white";
          },
          formatter: function (value, ctx) {
            if (!ctx.active) {
              return value + "%";
            } else if (ctx.dataIndex === correctAnswer - 1) {
              return "נכון";
            } else if (ctx.dataIndex === userAnswer - 1) {
              return "תשובתך";
            } else {
              return value + "%";
            }
          },
        },
      },
    ],
  };
  const bars_comp = (
    <div className="bottom-bars-container" dir="ltr">
      <Bar
        data={data}
        options={{
          layout: {
            padding: { top: 10 },
          },
          animation: {
            duration: 2300,
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          plugins: {
            labels: {
              render: "image",
              textMargin: 5,
              images: knowledge ? imagesByResult : [],
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                ticks: {
                  //Hebrew Version Change Here !!!
                  reverse: true,
                  fontSize: 26,
                  beginAtZero: true,
                  fontColor: "black",
                },
                gridLines: {
                  //xAxe Line
                  drawBorder: true,
                  color: "black",
                  drawOnChartArea: false,
                  drawTicks: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  );

  let allQuestDivs = [];
  let classNames = [
    "singleAnswer e1",
    "singleAnswer e2",
    "singleAnswer e3",
    "singleAnswer e4",
  ];
  classNames[userAnswer - 1] = "singleAnswer s" + userAnswer;
  if (knowledge) {
    classNames[correctAnswer - 1] += " correct-ans";
    console.log("good", "correct set to " + (correctAnswer - 1));
    console.log(classNames);
  }
  for (let index = 0; index < answers.length; index = index + 1) {
    let questDiv = (
      <button key={index} disabled={true} className={classNames[index]}>
        <div className="flexbox-row-single-ques">
          <div className="numberCircle">{index + 1}</div>
          <div className="text-inside-ans">{answers[index]}</div>
        </div>
      </button>
    );
    allQuestDivs.push(questDiv);
  }

  const ques_comp = (
    <div>
      <div className="clock-quest-container">
        <div className="clock-left" />
        <div className="question-center">{question}</div>
      </div>
      <div className="quest-container-answered">{allQuestDivs}</div>
    </div>
  );
  return (
    <div className="bars-ques-container">
      <audio autoPlay>
        <source src={"assets/bars/" + audioKey + ".mp3"} />
      </audio>
      {ques_comp}
      {bars_comp}
    </div>
  );
};

function imagesSetter(numOfAnswers, correctAnswer, userAnswer) {
  let def_size = 0;
  let imagesByResult = [];
  for (let index = 0; index < numOfAnswers; index++) {
    if (index + 1 === userAnswer || index + 1 === correctAnswer) {
      if (userAnswer === index + 1) {
        imagesByResult[index] = {
          //Wrong Ans
          src: incorrectSvg,
          width: def_size,
          height: def_size,
        };
      }
      if (correctAnswer === index + 1) {
        imagesByResult[index] = {
          //Correct Answer
          src: correctSvg,
          width: def_size,
          height: def_size,
        };
      }
    } else {
      imagesByResult[index] = null;
    }
  }
  return imagesByResult;
}

function castToPercent(distribution) {
  let total = 0;
  Object.values(distribution).forEach((element) => {
    total += element;
  });

  let distributionPercent = {};
  let tmp = 1;
  Object.keys(distribution).forEach((element) => {
    console.log(element);
    distributionPercent[tmp] = Math.round(
      (100 * distribution[element]) / total
    );
    tmp++;
  });
  return distributionPercent;
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const mapStateToProps = (state) => ({
  distribution: state.user.userState.phaseProp.distribution,
  correctAnswer: state.user.userState.phaseProp.correctAnswer,
  userAnswer: state.user.userState.phaseProp.userAnswer,
  correctTerm: state.user.userState.phaseProp.correctTerm,
  audioKey: state.user.userState.phaseProp.key,
  knowledge: state.user.userState.phaseProp.knowledge,
  question: state.user.userState.phaseProp.question,
  answers: state.user.userState.phaseProp.answers,
});

Bars.propTypes = {
  distribution: PropTypes.object.isRequired,
  correctAnswer: PropTypes.number.isRequired,
  userAnswer: PropTypes.number.isRequired,
  correctTerm: PropTypes.string.isRequired,
  audioKey: PropTypes.string.isRequired,
  knowledge: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {})(Bars);
