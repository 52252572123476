import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { UserAnswer } from "../../actions/user";
import "../layouts/css/Questions.css";
import { ScaleLoader } from "react-spinners";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buffering: true,
      question: false,
      answers: false,
      choose: false,
      choosed: false,
      selected: -1, // start from 0 . for present the currect answer
      key: 0,
    };
    //Audio Care
    this.audioBufferd = this.audioBufferd.bind(this);
    this.allBuffered = 0;
    this.questionTime = 0;
    this.answersTime = 0;
    this.waitingTime = 0;
    //Clock Care
    this.calledTime = new Date();
    this.windowL = this.windowListener.bind(this);
    //Keyboard Care
    this.indexes = Array.from(
      { length: props.answers.length },
      (_, index) => index + 1
    );
    this.keyboardL = this.keyboardPress.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.addListeners();
    this.audioDisable();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.audioDisable();
    this.windowsRemoveListeners();
  }
  windowsRemoveListeners() {
    window.removeEventListener("focus", this.windowL);
    document.removeEventListener("keydown", this.keyboardL);
  }

  addListeners() {
    window.addEventListener("focus", this.windowL);
    document.addEventListener("keydown", this.keyboardL);
    this.audio1 = document.getElementById("aud1");
    this.audio1.addEventListener("loadedmetadata", this.audioBufferd);
    this.audio2 = document.getElementById("aud2");
    this.audio2.addEventListener("loadedmetadata", this.audioBufferd);
    this.audio3 = document.getElementById("aud3");
    this.audio3.addEventListener("loadedmetadata", this.audioBufferd);
    navigator.mediaSession.setActionHandler("pause", function () {
      console.log("Pause not allowed");
    });
    navigator.mediaSession.setActionHandler("stop", function () {
      console.log("Stop not allowed");
    });
  }
  audioBufferd(e) {
    switch (e.target.id) {
      case "aud1":
        this.questionTime = e.target.duration * 1000;
        break;
      case "aud2":
        this.answersTime = e.target.duration * 1000;
        break;
      case "aud3":
        this.waitingTime = e.target.duration * 1000;
        break;
    }
    if (
      this.questionTime !== 0 &&
      this.answersTime !== 0 &&
      this.waitingTime !== 0
    ) {
      this.start();
      this.audio1.removeEventListener("loadedmetadata", this.audioBufferd);
      this.audio2.removeEventListener("loadedmetadata", this.audioBufferd);
      this.audio3.removeEventListener("loadedmetadata", this.audioBufferd);
    }
  }
  start() {
    this.setStatePromise({ buffering: false, question: true })
      .then(() => this.sleep(this.questionTime))
      .then(() => this.setStatePromise({ question: false, answers: true }))
      .then(() => this.sleep(this.answersTime))
      .then(() => this.setStatePromise({ answers: false, choose: true }))
      .then(() => this.sleep(this.waitingTime))
      .then(() => this.setStatePromise({ choose: false, choosed: true }));
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms + 230));
  }

  setStatePromise(state) {
    if (this._isMounted && !this.state.choosed) {
      this.setState(state);
    }
    return Promise.resolve();
  }
  audioDisable() {
    if (!this.audio1.paused) {
      this.audio1.pause();
    }
    if (!this.audio2.paused) {
      this.audio2.pause();
    }
    if (!this.audio3.paused) {
      this.audio3.pause();
    }
  }

  remainTimeFunc = () => {
    var remainTime =
      this.calledTime.getTime() +
      (this.questionTime + this.answersTime) +
      this.props.time * 1000 -
      new Date().getTime();
    return remainTime;
  };
  //Listener for focusing screen, synchronize the click time
  windowListener() {
    if (this.state.choose) {
      this.setState({ key: this.state.key + 1 });
    }
  }
  //Listener for keyboard press

  //Game Logic
  keyboardPress = (e) => {
    const char = parseInt(e.key);
    if (!isNaN(char) && this.indexes.includes(char) && this.state.choose) {
      this.onAnswerClick(char);
    }
  };

  onAnswerClick = (ind) => {
    this.setState({ choose: false, choosed: true, selected: ind - 1 });
    this.props.UserAnswer(
      ind,
      Math.round(this.remainTimeFunc()),
      this.props.quesNum
    );
  };

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div>0</div>;
    }
    return <div>{remainingTime}</div>;
  };
  render() {
    let allQuestDivs = [];
    let classNames;
    let clocktimer;
    if (this.state.question) {
      if (this.audio1.paused) {
        setTimeout(() => {
          this.audio1.play();
        }, 1);
      }
    } else if (this.state.answers) {
      if (!this.audio1.paused) {
        this.audio1.pause();
      }
      if (this.audio2.paused) {
        setTimeout(() => {
          this.audio2.play();
        }, 1);
      }
      classNames = [
        " singleAnswer s1",
        " singleAnswer s2",
        " singleAnswer s3",
        " singleAnswer s4",
      ];
      for (
        let index = 0;
        index < this.props.answers.length;
        index = index + 1
      ) {
        let questDiv = (
          <div key={index}>
            <button className={classNames[index]} disabled={true}>
              <div className="flexbox-row-single-ques">
                <div className={"numberCircle"}>{index + 1}</div>
                <div className="text-inside-ans">
                  {this.props.answers[index]}
                </div>
              </div>
            </button>
          </div>
        );
        allQuestDivs.push(questDiv);
      }
      clocktimer = (
        <CountdownCircleTimer
          isPlaying={false}
          size={Math.min(window.innerWidth, window.innerHeight) * 0.13}
          strokeWidth={Math.min(window.innerWidth, window.innerHeight) * 0.004}
          duration={this.props.time}
          colors={[
            ["#004777", 0.33],
            ["#F7B801", 0.33],
            ["#A30000", 0.33],
          ]}
          onComplete={() => {
            this.setState({ choose: false, choosed: true });
          }}
        >
          {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
      );
    } //Choose part
    else if (this.state.choose) {
      if (!this.audio2.paused) {
        this.audio2.pause();
      }
      if (this.audio3.paused) {
        setTimeout(() => {
          this.audio3.play();
        }, 1);
      }
      classNames = [
        "hovering singleAnswer s1",
        "hovering singleAnswer s2",
        "hovering singleAnswer s3",
        "hovering singleAnswer s4",
      ];
      for (
        let index = 0;
        index < this.props.answers.length;
        index = index + 1
      ) {
        let questDiv = (
          <button
            key={index}
            className={classNames[index]}
            onClick={() => this.onAnswerClick(index + 1)}
          >
            <div className="flexbox-row-single-ques">
              <div className={"numberCircle"}>{index + 1}</div>
              <div className="text-inside-ans">{this.props.answers[index]}</div>
            </div>
          </button>
        );
        allQuestDivs.push(questDiv);
      }
      let remainTime = this.remainTimeFunc();
      remainTime = remainTime > 500 ? remainTime : 0;
      clocktimer = (
        <CountdownCircleTimer
          key={this.state.key}
          size={Math.min(window.innerWidth, window.innerHeight) * 0.13}
          strokeWidth={Math.min(window.innerWidth, window.innerHeight) * 0.004}
          isPlaying={true}
          duration={this.props.time} // should be Time Left !
          initialRemainingTime={remainTime / 1000}
          colors={[
            ["#004777", 0.33],
            ["#F7B801", 0.33],
            ["#A30000", 0.33],
          ]}
          onComplete={() => {
            if (!this.audio3.ended) {
              this.audio3.pause();
            }
          }}
        >
          {(remainingTime) => this.renderTime(remainingTime)}
        </CountdownCircleTimer>
      );
    } else if (this.state.choosed) {
      classNames = [
        "singleAnswer e1",
        "singleAnswer e2",
        "singleAnswer e3",
        "singleAnswer e4",
      ];
      if (this.state.selected >= 0) {
        classNames[this.state.selected] =
          "singleAnswer s" + (this.state.selected + 1);
      }
      for (
        let index = 0;
        index < this.props.answers.length;
        index = index + 1
      ) {
        let questDiv = (
          <button key={index} disabled={true} className={classNames[index]}>
            <div className="flexbox-row-single-ques">
              <div className="numberCircle">{index + 1}</div>
              <div className="text-inside-ans">{this.props.answers[index]}</div>
            </div>
          </button>
        );
        allQuestDivs.push(questDiv);
      }
      let remainTime = this.remainTimeFunc();
      remainTime = remainTime > 500 ? remainTime : 0;

      clocktimer = (
        <CountdownCircleTimer
          size={Math.min(window.innerWidth, window.innerHeight) * 0.13}
          strokeWidth={Math.min(window.innerWidth, window.innerHeight) * 0.004}
          isPlaying
          duration={this.props.time} // should be Time Left !
          initialRemainingTime={remainTime / 1000}
          colors={[
            ["#004777", 0.33],
            ["#F7B801", 0.33],
            ["#A30000", 0.33],
          ]}
          onComplete={() => {
            if (!this.audio3.ended) {
              this.audio3.pause();
            }
          }}
        >
          {(remainingTime) => this.renderTime(remainingTime)}
        </CountdownCircleTimer>
      );
    }
    return (
      <div>
        {this.state.buffering && (
          <div>
            <audio id="aud1">
              <source
                src={"assets/question/" + this.props.quesNum + "/1.mp3"}
              />
            </audio>
            <audio id="aud2">
              <source
                src={"assets/question/" + this.props.quesNum + "/2.mp3"}
              />
            </audio>
            <audio id="aud3">
              <source
                src={"assets/question/timer/" + this.props.time + "sec.mp3"}
              />
            </audio>
          </div>
        )}

        {this.state.question && (
          <div className="big-container ">
            <div className="clock-quest-container start-part">
              <div className="clock-left">{}</div>
              <div className=" question-center">{this.props.question}</div>
            </div>
            <div>
              <ScaleLoader />
            </div>
          </div>
        )}
        {this.state.answers && (
          <>
            <div className="quest-animation clock-quest-container">
              <div className="animation-appear clock-left">{clocktimer}</div>
              <div className="question-center">{this.props.question}</div>
            </div>
            <div className="animation-appear quest-container-answered">
              {allQuestDivs}
            </div>
          </>
        )}
        {(this.state.choose || this.state.choosed) && (
          <>
            <div className="clock-quest-container">
              <div className="clock-left">{clocktimer}</div>
              <div className="question-center">{this.props.question}</div>
            </div>
            <div className="quest-container-answered">{allQuestDivs}</div>
          </>
        )}
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.array,
  time: PropTypes.number,
  UserAnswer: PropTypes.func.isRequired,
  quesNum: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  question: state.user.userState.phaseProp.question,
  answers: state.user.userState.phaseProp.answers,
  time: state.user.userState.phaseProp.time,
  quesNum: state.user.userState.phaseProp.key,
});

export default connect(mapStateToProps, { UserAnswer })(Question);
