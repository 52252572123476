import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import "../../App.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { initGame } from "../../actions/dashboard";
import GameDashboard from "./GameDashboard";
const ControlPanel = ({ name, logout, initGame }) => {
  const [gameStarted, setGameStarted] = useState(false);
  // const [numOfPlayers, setPlayers] = useState(15);
  // const [gameKey, setGameKey] = useState("1");
  // const [gameType, setGameType] = useState("1")

  const [gameData, setGameData] = useState({
    numOfPlayers: 15,
    gameKey: "1",
    gameType: 1,
  });

  const onChange = (e) =>
    setGameData({ ...gameData, [e.target.name]: e.target.value });

  // const onChange = (e) => setPlayers(e.target.value);
  const onSubmit = async (e) => {
    if (gameData.numOfPlayers === "") {
      alert("Please enter number");
    } else {
      setGameStarted(true);
      initGame(name, gameData); //changed signature
    }
  };

  if (gameStarted) return <GameDashboard controlpanel={setGameStarted} />;

  const { numOfPlayers, gameKey, gameType } = gameData;

  return (
    <span>
      <h1 className='aaa'> ברוך הבא ללוח הבקרה{name} </h1>
      <button onClick={logout}>התנתק</button>
      <form className='form' onSubmit={(e) => onSubmit(e)}>
        <div className='flex-container-user-col'>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h4>הכנס מספר שחקנים</h4>
            <TextField
              type='number'
              placeholder='מס שחקנים'
              name='numOfPlayers'
              style={{ padding: 20 }}
              value={numOfPlayers}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h4>הכנס קוד משחק:</h4>
            <TextField
              type='text'
              placeholder='קוד משחק'
              name='gameKey'
              style={{ padding: 20 }}
              value={gameKey}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <RadioGroup
            aria-label='gender'
            name='gameType'
            value={gameType}
            onChange={(e) => onChange(e)}
          >
            <FormControlLabel value='1' control={<Radio />} label='סמים' />
            {/* <FormControlLabel
              value="2"
              control={<Radio />}
              label="Cinema Drive"
            />
            <FormControlLabel value="3" control={<Radio />} label="Bully" /> */}
          </RadioGroup>

          <button onClick={onSubmit}>צור משחק</button>
        </div>
      </form>
    </span>
  );
};
ControlPanel.propTypes = {
  logout: PropTypes.func.isRequired,
  name: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated, //TODO:: DELETE THIS
  name: state.auth.name,
});

export default connect(mapStateToProps, { logout, initGame })(ControlPanel);
