import { CREATE_NEW_GAME_INSTANCE, USER, PHASE } from "../actions/types";

const initialState = {
  phaseList: [],
  GameKey: "000",
  usersData: {},
  numOfPlayers: 0,
  curPhase: -1,
  reConnect: false,
};

export default function dashBoardReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_NEW_GAME_INSTANCE:
      return {
        ...state,
        phaseList: payload.phaseList,
        GameKey: payload.keyGame,
        numOfPlayers: payload.numOfPlayers,
        reConnect: payload.reConnect,
      };

    case USER:
      return {
        ...state,
        usersData: payload.usersData,
      };
    case PHASE:
      return {
        ...state,
        curPhase: payload.phaseIndex,
      };
    case "CLEAR":
      console.log("CLEAR!");
      return {
        phaseList: [],
        GameKey: "000",
        usersData: {},
        numOfPlayers: 0,
        curPhase: -1,
        reConnect: false,
      };
    default:
      return state;
  }
}
