import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/user";
import { Redirect } from "react-router-dom";
import { loadState } from "../../localStorage";
import "../layouts/css/LoginUser.css";
const LoginUser = ({ login, isAuthenticated }) => {
  const [cookie, setCookie] = useState(loadState());
  const [formData, setFormData] = useState({
    gameKey: "",
  });
  // var load = null;
  // useEffect(() => {
  //   load = loadState();
  // }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    if (cookie != null) {
      if (cookie.gameKey == gameKey) {
        login({ number: cookie.number, gameKey: cookie.gameKey });
        return;
      }
    }
    login({ gameKey });
  };

  if (isAuthenticated) {
    return <Redirect to="/game" />;
  }

  const { gameKey } = formData;
  return (
    <Fragment>
      <div className="flex-container-user-main ">
        <h1>ברוכים הבאים </h1>
        <h1 style={{ marginTop: "2%" }}>
          אנא מלאו את את קוד המשחק הניתן לכם על ידי המנחה{" "}
        </h1>
        <form onSubmit={(e) => onSubmit(e)}>
          <div style={{ marginTop: "10%" }}>
            <input
              type="text"
              placeholder="קוד משחק"
              name="gameKey"
              value={gameKey}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div style={{ marginTop: "15%" }}>
            <button type="submit" color="primary">
              היכנס/י
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

LoginUser.propTypes = {
  isAuthenticated: PropTypes.bool,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginUser);
